<template>
  <v-container>
    <v-row class="mb-4">
      <h2>Report Sheet: Separation of a Mixture</h2>
    </v-row>

    <v-row class="mb-2"> a) What was the identification code of your unknown? </v-row>

    <calculation-input
      v-model="inputs.unknownNumber"
      dense
      prepend-text="$\text{Code}:$"
      class="mb-8"
      :disabled="!allowEditing"
    />

    <v-row class="mb-3">
      b) Fill in the data table below with the data you collected during the lab and related
      calculations.
    </v-row>

    <v-row class="mb-7">
      <v-simple-table>
        <thead>
          <tr>
            <td>
              <stemble-latex content="$\text{Property}$" />
            </td>
            <td>
              <stemble-latex content="$\text{Value}$" />
            </td>
          </tr>

          <tr>
            <td>Mass of clean dry evaporating dish (g)</td>
            <td>
              <calculation-input v-model="inputs.massDryDish" :disabled="!allowEditing" dense />
            </td>
          </tr>

          <tr>
            <td>Mass of evaporating dish + sample (g)</td>
            <td>
              <calculation-input
                v-model="inputs.massDryDishPlusSample"
                :disabled="!allowEditing"
                dense
              />
            </td>
          </tr>

          <tr>
            <td>Mass of original sample (g)</td>
            <td>
              <calculation-input v-model="inputs.massSample" :disabled="!allowEditing" dense />
            </td>
          </tr>

          <tr>
            <td />
            <td />
          </tr>

          <tr>
            <td>Mass of evaporating dish + sample (g)</td>
            <td>
              <calculation-input
                v-model="inputs.massDryDishPlusSample"
                :disabled="!allowEditing"
                dense
              />
            </td>
          </tr>

          <tr>
            <td>Mass of evaporating dish + sample AFTER sublimation (g)</td>
            <td>
              <calculation-input
                v-model="inputs.massDryDishPlusSampleAfterSublimation"
                :disabled="!allowEditing"
                dense
              />
            </td>
          </tr>

          <tr>
            <td>Mass of ammonium chloride (g)</td>
            <td>
              <calculation-input v-model="inputs.massNH4Cl" :disabled="!allowEditing" dense />
            </td>
          </tr>

          <tr>
            <td>Mass of original sample (g)</td>
            <td>
              <calculation-input v-model="inputs.massSample" :disabled="!allowEditing" dense />
            </td>
          </tr>

          <tr>
            <td>Percent ammonium chloride in sample (%)</td>
            <td>
              <calculation-input v-model="inputs.pctNH4Cl" :disabled="!allowEditing" dense />
            </td>
          </tr>

          <tr>
            <td />
            <td />
          </tr>

          <tr>
            <td>Mass of empty evaporating dish + watch glass (g)</td>
            <td>
              <calculation-input
                v-model="inputs.massDishPlusWatchGlass"
                :disabled="!allowEditing"
                dense
              />
            </td>
          </tr>

          <tr>
            <td>Mass of evaporating dish + watch glass + salt after heating to dryness (g)</td>
            <td>
              <calculation-input
                v-model="inputs.massDishPlusWatchGlassPlusSaltAfterHeating"
                :disabled="!allowEditing"
                dense
              />
            </td>
          </tr>

          <tr>
            <td>Mass of sodium chloride (g)</td>
            <td>
              <calculation-input v-model="inputs.massNaCl" :disabled="!allowEditing" dense />
            </td>
          </tr>

          <tr>
            <td>Mass of original sample (g)</td>
            <td>
              <calculation-input v-model="inputs.massSample" :disabled="!allowEditing" dense />
            </td>
          </tr>

          <tr>
            <td>Percent sodium chloride in sample (%)</td>
            <td>
              <calculation-input v-model="inputs.pctNaCl" :disabled="!allowEditing" dense />
            </td>
          </tr>

          <tr>
            <td />
            <td />
          </tr>

          <tr>
            <td>Mass of clean dry evaporating dish (g)</td>
            <td>
              <calculation-input v-model="inputs.massDryDish" :disabled="!allowEditing" dense />
            </td>
          </tr>

          <tr>
            <td>Mass of evaporating dish + sand after heating to dryness (g)</td>
            <td>
              <calculation-input
                v-model="inputs.massDishPlusSandAfterHeating"
                :disabled="!allowEditing"
                dense
              />
            </td>
          </tr>

          <tr>
            <td>Mass of silicon dioxide (g)</td>
            <td>
              <calculation-input v-model="inputs.massSiO2" :disabled="!allowEditing" dense />
            </td>
          </tr>

          <tr>
            <td>Mass of original sample (g)</td>
            <td>
              <calculation-input v-model="inputs.massSample" :disabled="!allowEditing" dense />
            </td>
          </tr>

          <tr>
            <td>Percent silicon dioxide in sample (%)</td>
            <td>
              <calculation-input v-model="inputs.pctSiO2" :disabled="!allowEditing" dense />
            </td>
          </tr>

          <tr>
            <td />
            <td />
          </tr>

          <tr>
            <td>Mass of original sample (g)</td>
            <td>
              <calculation-input v-model="inputs.massSample" :disabled="!allowEditing" dense />
            </td>
          </tr>

          <tr>
            <td>TOTAL mass of ALL recovered components (g)</td>
            <td>
              <calculation-input
                v-model="inputs.massTotalRecovered"
                :disabled="!allowEditing"
                dense
              />
            </td>
          </tr>

          <tr>
            <td>Percent recovery (%)</td>
            <td>
              <calculation-input v-model="inputs.pctRecovered" :disabled="!allowEditing" dense />
            </td>
          </tr>
        </thead>
      </v-simple-table>
    </v-row>

    <v-row class="mb-2"> c) Is the percent recovery you obtained good? Explain your answer. </v-row>

    <v-row class="mb-5">
      <s-textarea v-model="inputs.explainedResponse" outlined :counter="true" :maxlength="600" />
    </v-row>
  </v-container>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import CalculationInput from '@/tasks/components/inputs/CalculationInput';
import STextarea from '@/common/components/STextarea.vue';

export default {
  name: 'OleMiss116Lab2ReportSheet',
  components: {
    STextarea,
    StembleLatex,
    CalculationInput,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        unknownNumber: null,

        massDryDish: null,
        massDryDishPlusSample: null,
        massSample: null,

        // massDryDish: null,
        massDryDishPlusSampleAfterSublimation: null,
        massNH4Cl: null,
        // massSample: null,
        pctNH4Cl: null,

        massDishPlusWatchGlass: null,
        massDishPlusWatchGlassPlusSaltAfterHeating: null,
        massNaCl: null,
        // massSample: null,
        pctNaCl: null,

        // massDryDish: null,
        massDishPlusSandAfterHeating: null,
        massSiO2: null,
        // massSample: null,
        pctSiO2: null,

        // massSample: null,
        massTotalRecovered: null,
        pctRecovered: null,

        explainedResponse: null,
      },
    };
  },
};
</script>

<style>
input[type='number'] {
  -moz-appearance: textfield; /*For FireFox*/

  &::-webkit-inner-spin-button {
    /*For Webkits like Chrome and Safari*/
    -webkit-appearance: none;
    margin: 0;
  }
}
</style>
